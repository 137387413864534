<template>
  <div>
    <vs-row class="upload-container">
      <template v-if="files && files.length">
        <vs-col class="md:w-1/3" v-for="(file, index) in files" :key="index">
          <div class="upload-file-content">
            <button class="remove-file" @click="removeFile(file)">
              <i class="onpoint-x"></i>
            </button>
            <div class="upload-file-text-content">
              <p>{{ file.name }}</p>
            </div>
          </div>
        </vs-col>
      </template>

      <vs-col class="md:w-1/3">
        <div class="drag-drop-file" @drop="setValue($event.dataTransfer.files)">
          <label class="drag-drop-file-text-content" for="upload">
            <i class="onpoint-file"></i>
            <p class="text">Arraste ou clique aqui</p>
          </label>

          <input
            id="upload"
            type="file"
            name="avatar"
            multiple
            @change="setValue($event.target.files)"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="end">
        <vs-button @click.native="uploadDocument()" color="primary">
          <span class="font-semibold pr-2 pl-2">
            Enviar documentos
          </span>
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const events = ["dragenter", "dragover", "dragleave", "drop"];

export default {
  name: "upload",
  props: {
    value: { type: Array, default: () => [] }
  },
  computed: {
    files: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions("seguradora-module", ["uploadDocumentos"]),

    async uploadDocument() {
      if (!this.files || this.files.length == 0) {
        this.$onpoint.errorModal("Selecione um documento.");
        return;
      }

      await this.$onpoint.loadingModal(
        `Estamos enviando ${
          this.files.length > 1 ? "seus documentos." : "seu documento."
        }`,
       async () => {
          return await this.uploadDocumentos(this.files)
            .then(response => {
              this.files = [];
              this.$emit("updateDocs", response);
            })
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            });
        }
      );
    },

    preventDefaults(e) {
      e.preventDefault();
    },

    setValue(fileList) {
      fileList.forEach(file => {
        let fileSize = file.size;
        let fileMb = fileSize / 1024 ** 2;
        if (fileMb > 25) {
          this.$onpoint.errorModal(
            "Arquivo muito grande, escolha arquivos até 25mb e tente novamente!"
          );
        } else {
          if (!this.files.includes(file)) this.files.push(file);
        }
      });
    },

    removeFile(file) {
      this.files = this.files.filter(x => x != file);
    }
  },
  mounted() {
    events.forEach(eventName => {
      document.body.addEventListener(eventName, this.preventDefaults);
    });
  },
  beforeDestroy() {
    events.forEach(eventName => {
      document.body.removeEventListener(eventName, this.preventDefaults);
    });
  }
};
</script>

<style lang="scss">
.upload-container {
  display: inline-flex;

  .drag-drop-file {
    width: 125px;
    height: 115px;

    /* grayscale/gray-4 */

    border: 1px dashed #8e8e8e;
    border-radius: 2px;
    cursor: pointer;

    input {
      width: 120px;
      height: 110px;
    }

    .drag-drop-file-text-content {
      position: absolute;
      width: 122px;
      height: 112px;
      background: white;
      display: block;
      padding: 28px 20px;
      cursor: pointer;

      i {
        justify-content: center;
        display: flex;
      }
      p {
        width: 100%;
        font-size: 14px;
        justify-content: center;
        display: flex;
        padding-top: 7px;
        text-align: justify;
        text-justify: inter-word;
      }
    }
  }

  .upload-file-content {
    width: 125px;
    height: 115px;
    margin-bottom: 20px;
    padding: 28px 20px;

    /* grayscale/gray-4 */

    border: 1px dashed #8e8e8e;
    border-radius: 2px;

    .remove-file {
      display: block;
      border: none;
      background: none;
      transform: translate(84px, -21px);
      color: #8e8e8e;
      position: absolute;
      border-radius: 50%;
      cursor: pointer;
      display: none;

      &:hover {
        color: #626262;
      }
    }

    &:hover {
      transform: translate(1px, -1px);
      box-shadow: -4px 4px 10px #8e8e8e5e;

      .remove-file {
        display: flex;
      }
    }

    .upload-file-text-content {
      height: 63px;
      background: white;
      display: block;
      border-radius: 6px;
      word-break: break-word;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
