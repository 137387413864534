var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "required-documents" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup-list-documents",
          attrs: { title: "", active: _vm.showModalDocuments },
          on: {
            "update:active": function ($event) {
              _vm.showModalDocuments = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mb-8 px-8" },
            [
              _vm.detalhamento.HasPendingDocumentsToUpload
                ? _c("span", { staticClass: "badge" }, [
                    _c("span", { staticClass: "material-icons" }, [
                      _vm._v("contact_support"),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "vs-tabs",
                {
                  staticClass: "required-documents-tabs pr-1",
                  attrs: { color: "warning" },
                },
                [
                  _c(
                    "vs-tab",
                    { attrs: { label: "Follow Up" } },
                    [
                      !(_vm.detalhamento.Observations || []).length
                        ? _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full mt-2" },
                              [
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "h-auto",
                                    attrs: { color: "warning" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Nenhum follow up cadastrado\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "vs-row",
                            _vm._l(
                              _vm.detalhamento.Observations,
                              function (mensagem, index) {
                                return _c(
                                  "vs-card",
                                  { key: index, staticClass: "card-follow-up" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex items-baseline title-card",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-primary font-bold mr-2 mb-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  mensagem.CreateByPerson
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            "\n                  escreveu em\n                  " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  new Date(mensagem.CreateDate),
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("p", [
                                      _vm._v(_vm._s(mensagem.Observation)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "vs-tab",
                    { attrs: { label: "Lista de documentos" } },
                    [
                      _c(
                        "vs-alert",
                        {
                          staticStyle: { "margin-bottom": "15px" },
                          attrs: {
                            color: "primary",
                            title: "Aviso!",
                            active: "true",
                          },
                        },
                        [
                          _vm._v(
                            '\n            Após o upload de todos os documentos obrigatórios clique no botão\n            "Enviar para seguradora"\n          '
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "mb-5" }, [
                        _vm._v("Documentos marcados com * são obrigatórios"),
                      ]),
                      _c(
                        "vs-list",
                        _vm._l(
                          _vm.detalhamento.RequestDocuments,
                          function (doc, index) {
                            return _c(
                              "vs-list-item",
                              {
                                key: index,
                                class: doc.StatusName,
                                attrs: {
                                  title:
                                    doc.Name + (doc.IsRequired ? " *" : ""),
                                  subtitle:
                                    doc.StatusDescription ===
                                    "Aguardando análise"
                                      ? "Documento Anexado"
                                      : doc.StatusDescription,
                                },
                              },
                              [
                                _vm._v(_vm._s(doc.Files) + "\n              "),
                                doc.StatusDescription !== "Conforme"
                                  ? _c("vs-button", {
                                      attrs: {
                                        size: "large",
                                        type: "flat",
                                        icon: "cloud_upload",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalUploadOpen(doc)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("p", { staticClass: "mb-2" }, [_vm._v("Comentário")]),
                      _c("vs-textarea", {
                        attrs: {
                          placeholder:
                            "Ex.: Analisar IR dos sócios fiadores / Analisar balanços dos fiadores, etc.",
                        },
                        model: {
                          value: _vm.observation,
                          callback: function ($$v) {
                            _vm.observation = $$v
                          },
                          expression: "observation",
                        },
                      }),
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(
                          "*Lembre de fazer upload dos documentos a serem analisados clicando em “Lista de documentos”"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right mt-5 w-full" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { color: "primary", type: "filled" },
                          on: {
                            click: function ($event) {
                              return _vm.finalizarPendencia()
                            },
                          },
                        },
                        [_vm._v("Enviar para seguradora")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.showModalDocuments
        ? _c(
            "vs-popup",
            {
              staticClass: "popup-upload-documento",
              staticStyle: { "z-index": "54000" },
              attrs: { title: "Upload de documento", active: _vm.modalUpload },
              on: {
                "update:active": function ($event) {
                  _vm.modalUpload = $event
                },
              },
            },
            [
              _c("div", { staticClass: "px-4" }, [
                _c("h4", [_vm._v(_vm._s((_vm.selectedDocument || {}).Name))]),
              ]),
              _c(
                "div",
                { staticClass: "px-4" },
                [
                  _c("upload-component", {
                    on: { updateDocs: _vm.uploadDocument },
                    model: {
                      value: _vm.files,
                      callback: function ($$v) {
                        _vm.files = $$v
                      },
                      expression: "files",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }