<template>
  <div
    v-if="tipoCanalCorretora.Descricao"
    class="tag-canal-corretor"
    :class="view"
  >
    <p>
      <span class=" tag-canal-corretor-prefix">Canal: </span>
      <span class=" tag-canal-corretor-canal">{{
        obterApenasOCanal(tipoCanalCorretora.Descricao)
      }}</span>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    tipoCanalCorretora: {
      type: Object,
      default: function() {
        return {
          Id: "",
          Nome: "",
          Descricao: ""
        }
      }
    },
    view: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    obterApenasOCanal(string) {
      if (this.tipoCanalCorretora.Descricao) {
        return string
          .split(" ")
          .slice(1)
          .join(" ");
      }
    }
  }
};
</script>
<style scoped lang="scss">
.tag-canal-corretor {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: underline;
  color: rgba(var(--vs-primary), 1);

  &-prefix {
    font-weight: 700;
    color: #626262;
  }

  &-canal {
    font-weight: 700;
    color: rgba(var(--vs-primary), 1);
  }
}

.timeline {
  justify-content: flex-start;
  padding: 0;
}

.proposta {
  justify-content: flex-end;
  padding: 0 2rem;
}

.editar-proposta{
  justify-content: flex-end;
  padding: 0;
}
</style>
